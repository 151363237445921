import Container from '@mui/material/Container';
import OrdersSection from '../components/OrdersSection'


export default function OrderPage({mediaType}) {
    return (
        <Container sx = {{display:'flex',flexDirection:'column',alignItems:'center',gap:'100px',marginTop:'100px'}}>
            <OrdersSection mediaType={mediaType} />
            <div ><br/><br/><br/><br/></div>

        </Container>

    )
}
