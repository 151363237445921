import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';


import webDesignImgUrl from './../images/webdesign.webp';
import seoImgUrl from './../images/SEO.jpg';
import networkImgUrl from './../images/NetworkSchematic.jpg';
import blockChainImgUrl from './../images/Blockchain-800x251.jpg'

export default function BlogPostCategories({mediaType}) {
    const {t,i18n} = useTranslation(['blogGeneral'])
  return (
    <Box
        sx = {{
            width:'100%',
            margin:'0 auto',
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            gap:'50px'
        }}
    >
        <Typography
            variant='h5'
            sx = {{
                alignSelf:'start',
            }}
        >
            {t('categoriesTitle')}
        </Typography>
        <ImageList 
            sx={{ 
                width: '80%', 
                
            }} 
            cols={(mediaType === 'isDesktop') ? 2 : 
                    (mediaType === 'isTablet') ? 2 : 1
                 }
        >
        {samplesData.map((item) => (
            <ImageListItem key={item.title} sx = {{margin:'10px',cursor:'pointer'}}>
            <img                
                srcSet={`${item.imgUrl}?w=248&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.imgUrl}?w=248&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
            />
            <ImageListItemBar
                sx = {{textAlign:'center',margin:'10px 0 20px 0'}}
                title={t(item.title)}
                subtitle={<span>{t(item.subtitle)}</span>}
                position="below"
            />
            </ImageListItem>
        ))}
        </ImageList>

    </Box>
  );
}


const samplesData = [
    {
        title: 'categores.0.title',
        imgUrl: webDesignImgUrl,
    },
    {
        title: 'categores.1.title',
        imgUrl: seoImgUrl,
    },
    {
        title: 'categores.2.title',
        imgUrl: networkImgUrl,
    },
    {
        title: 'categores.3.title',
        imgUrl: blockChainImgUrl,
    },
];
