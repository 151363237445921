import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import TextImage from './../components/TextImage'

import companyImgUrl from './../images/companySample1.jpg'
import shopImgUrl from './../images/shoppingSample1.jpg'
import blogImgUrl from './../images/weblogSample.png'
 

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const productsList = [
    {
        id:'0',
        name:'company.0.title',
        abstract: 'company.1.abstract',
        imgUrl: companyImgUrl,
        productUrl: '/product-company'
    },
    {
        id:'1',
        name:'shopping.0.title',
        abstract: 'shopping.1.abstract',
        imgUrl: shopImgUrl,
        productUrl: '/product-shopping'
    },
    {
        id:'2',
        name:'blog.0.title',
        abstract: 'blog.1.abstract',
        imgUrl: blogImgUrl,
        productUrl: '/product-blog'
    },
]


const cards = productsList.map((item,index) => {
    <TextImage imgUrl={item.imgUrl} title = {item.name} context = {item.abstract}/>
})


function ProductsGallery({mediaType}) {
  const {t,i18n} = useTranslation(['products','common'])
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = productsList.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          pl: 2,
          bgcolor: 'background.default',
        }}
      >
      </Paper>
      <AutoPlaySwipeableViews
        axis={i18n.dir() === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {productsList.map((item, index) => (
          <div key={item.id}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Link to = {item.productUrl} style = {{textDecoration:'none'}}>
                <TextImage mediaType = {mediaType} imgUrl={item.imgUrl} title = {t(item.name)} context = {t(item.abstract)}/>              
              </Link>
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        sx = {{ width:'50%', margin:'0 auto'}}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            {t('next',{ns:'common'})}
            {i18n.dir() === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {i18n.dir() === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            {t('back',{ns:'common'})}
          </Button>
        }
      />
    </Box>
  );
}

export default ProductsGallery;
