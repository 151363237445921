import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import TextImage from "./TextImage";
import post1ImgUrl from './../images/post1.jpg';
import post2ImgUrl from './../images/post2.jpg';

export default function BlogPostsGallery({mediaType}) {
    const {t,i18n} = useTranslation(['blogGeneral']);


    const postsList = [
        {
            id:'0',
            title:'post1.0.title',
            abstract: 'post1.1.abstract',
            imgUrl: post1ImgUrl,
            postURL:'blog-post1'
        },
        {
            id:'1',
            title:'post2.0.title',
            abstract: 'post2.1.abstract',
            imgUrl: post2ImgUrl,
            postURL:'blog-post2'
        },
    ]
    return (
        <Box
            sx = {{
                width:'100%'
            }}
        >
            <Typography
                variant="h5"
            >
                {t("HomePageTitle")}
            </Typography>
            {
                postsList.map((item,index) => {
                    return (
                        <Link to = {item.postURL} style = {{textDecoration:'none'}}>
                            <TextImage mediaType = {mediaType} key = {item.title} imgUrl={item.imgUrl} title = {t(item.title)} context = {t(item.abstract)} imgLoc={index % 2 + 1}/>
                        </Link>
                    )
                })
            }

        </Box>
    )
}