import { useState,useEffect } from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import MenuItem from '@mui/material/MenuItem';
import LanguageSelector from "./LanguageSelector";
import SearchAppBar from './SearchAppBar';
import MyDrawer from "./Drawer"
import MenuList from '@mui/material/MenuList';
import { Link } from "react-router-dom"




export default function NavBar({mediaType,forFooter}) {
    const [isScrolling,setIsScrolling] = useState(false);
    const {t,i18n} = useTranslation(['navbar']);

    useEffect(() => {
        window.addEventListener('scroll',handleScrolling);
        return () => {
            window.removeEventListener('scroll',handleScrolling);
        }
    })
    const handleScrolling = () => {
        if (window.scrollY >= window.innerHeight / 2) {
            setIsScrolling(true);
        } else {
            setIsScrolling(false);
        }
    }
    const menuItemNames = [
        'home',
        'about',
        'samples',
        'order',
        'blog',
        'contact'
    ]
    const menuItems = menuItemNames.map((item,index) => {
        return (
            <MenuItem
                key = {index}
                >
                <Link to = {'/' + item} style = {{textDecoration:'none'}}>
                    <Typography 
                        sx = {{
                            fontFamily: (i18n.language === 'fa') ? 'Vazirmatn' : 'Roboto',
                            color:'#000'
                        }}
                    >
                    {t(item)}
                    </Typography>
                </Link>
            </MenuItem>
        )
    })

    const mainNavbar = (
            <Box
                sx = {{flexGrow:1}}
            >
            { 
                (mediaType === 'isDesktop') &&
                <Box
                    sx = {{
                        position:(isScrolling) ? 'fixed' : 'none',
                        top:(isScrolling) ? '0' : 'none',
                        left:(isScrolling) ? 'calc(window.innerWidth * 0.05)' : 'none',
                        backgroundColor:(isScrolling) ? '#fff' : 'transparent',
                        zIndex:'100',
                        display:'flex',
                        alignItems:'center',
                        width:'100%'
                    }}
                >
                    <AppBar
                        position="static"
                        sx = {{
                            margin:'0 auto',
                            width:'90%',
                            // backgroundColor:'transparent',
                            backgroundColor:(isScrolling) ? '#fff' : 'transparent',
                            display:'flex',
                            flexDirection:'row',
                            alignItems:'center',
                            padding:'0 10px',

                        }}
                    >
                        {(i18n.dir().toString() === 'ltr') && <SearchAppBar />}
                        {(i18n.dir().toString() === 'rtl') && <LanguageSelector />}
                        <Toolbar
                            variant="regular"
                            sx = {{
                                width:'50%',
                                margin:'0 auto',
                                display:'flex',
                                flexDirection:'row',
                                justifyContent:'space-between',
                                color:'#000',
                            }}
                        >
                            {menuItems}
                        </Toolbar>
                        {(i18n.dir().toString() === 'ltr') && <LanguageSelector mediaType={mediaType}/>}
                        {(i18n.dir().toString() === 'rtl') && <SearchAppBar />}
                    </AppBar>
                </Box>
            }
            {
                (mediaType === 'isMobile' || mediaType === 'isTablet') &&
                <MyDrawer mediaType = {mediaType}/>
            }
            {
                (mediaType === 'isMobile' || mediaType === 'isTablet') &&
                <LanguageSelector mediaType={mediaType}/>
            }
        </Box>

        )
        const mobileStyle = {
            display:'grid',
            gridTemplateColumns: 'Repeat(3,1fr)',
            justifyContent:'center',
        }

        const desktopStyle = {

        }
        const tabletStyle = {
            display:'flex',
            flexDirection:'column',
            alignItems:'end'
        }

    const footerNavBar = () => {
          return (
            <Box sx={{ width: 230}}>
              <MenuList
                sx = {(mediaType === 'isDesktop') ? desktopStyle :
                     (mediaType === 'isTablet') ? tabletStyle :
                     (mediaType === 'isMobile') ? mobileStyle : mobileStyle}
              >
                {
                    menuItemNames.map((item,index) => {
                        return (
                            <MenuItem key={index}> 
                                <Link to = {item} style={{textDecoration:"none"}}>
                                    <Typography  variant="inherit" sx = {{color:"#fff"}}>{t(item)}</Typography>                                                                
                                </Link>
                            </MenuItem>
                        )    
                    })
                }
              </MenuList>
            </Box>
          );
        }

    return (
        <Box>
            {
                (forFooter) ? footerNavBar() : mainNavbar
            }

        </Box>
    )
}