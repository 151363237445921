import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import { Link } from 'react-router-dom';

export default function MyDrawer({mediaType}) {
  const [open, setOpen] = React.useState(false);
  const {t,i18n} = useTranslation(['navbar']);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const menuItems = [
    {name:'home',icon:<HomeIcon />},
    {name:'about',icon:<InfoIcon />},
    {name:'samples',icon:<WorkspacesIcon />},
    {name:'order',icon:<ShoppingCartIcon />},
    {name:'blog',icon:<LaptopChromebookIcon />},
    {name:'contact',icon:<PhoneEnabledIcon />},
 ]

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        {menuItems.map((item, index) => (
          <Link to = {item.name} style = {{textDecoration:'none'}}>
            <ListItem key={item.name} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={t(item.name)} sx = {{color:'#000'}} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <Button 
        onClick={toggleDrawer(true)}
        sx = {
            (mediaType === 'isMobile') ?
            {
                position:'fixed',
                top:'10px',
                right:'10px',
            } : (mediaType === 'isTablet') ?
                {
                    position:'fixed',
                    top:'30px',
                    right:'30px',
                } :
                {}
        }
      >
        <MenuIcon
            sx = {{
                color:'#000',
                fontSize: (mediaType === 'isMobile') ? '24px' : '50px'
            }}            
        />
      </Button>
      <Drawer 
            anchor = {(i18n.dir() === 'rtl' ? 'right' : 'left')} 
            open={open} 
            onClose={toggleDrawer(false)}
      >
        {DrawerList}
      </Drawer>
    </div>
  );
}
