import Box from '@mui/material/Box'
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export default function SocialMediaIconSet() {
    return (
        <Box
            sx = {{
                display:'flex',
                flexDirection:'row',
                alignItems:'center',
                justifyContent:'space-between',
            }}
        >
            <FacebookIcon sx = {{cursor:'pointer'}}/>
            <a href="https://x.com/codinggeek1984" style = {{color:'#fff'}}>
                <XIcon sx = {{cursor:'pointer'}}/>
            </a>
            <a href="https://www.instagram.com/rayan_webhub/" style={{color:'#fff'}}>
                <InstagramIcon sx = {{cursor:'pointer'}}/>
            </a>
            <LinkedInIcon sx = {{cursor:'pointer'}}/>
            <WhatsAppIcon sx = {{cursor:'pointer'}}/>
        </Box>

    )
}