import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import NavBar from "./NavBar";
import SocialMediaIconSet from "./SocialMediaIconSet";

export default function Footer({mediaType}){
    const {t,i18n} = useTranslation(['footer']);
    return (
        <Box
            sx = {{
                width:'100vw',
                backgroundColor:'#000',
                color:'#fff',
                padding:(mediaType === 'isDesktop') ? '100px 0' :
                        (mediaType === 'isTablet') ? '100px 0' :
                        (mediaType === 'isMobile') ? '10px 0' : '200px 0',
            }}
        >
            <Box
                sx = {{
                    width:'80%',
                    margin:'100px auto',
                }}
            >
                <Typography
                    variant="h4"
                    sx = {{
                        textAlign:'center',
                    }}
                >
                    {t('title')}
                </Typography>
                <Box
                    sx = {{
                        display:'flex',
                        flexDirection:(mediaType === 'isDesktop')   ? 'row' :
                                      (mediaType === 'isTablet')    ? 'row':
                                      (mediaType === 'isMobile')    ? 'column':'column',
                        alignItems:'center',
                        justifyContent:'space-between',
                        margin: (mediaType === 'isDesktop') ? '0px 50px 0 50px' :
                                (mediaType === 'isTablet')  ? '100px 0px 0 0px' :
                                (mediaType === 'isMobile')  ? '100px 0px 0 0px' : '200px 0px 0 0px',
                    }}
                >
                    <Box
                        sx = {{
                            order: (mediaType !== 'isMobile') ? 1 : 2,
                            display:'flex',
                            flexDirection: (mediaType === 'isDesktop') ? 'column' :
                            (mediaType === 'isTablet') ? 'column' :
                            (mediaType === 'isMobile') ? 'column' : 'column',
                            alignItems: (mediaType === 'isDesktop') ? 'center' :
                            (mediaType === 'isTablet') ? 'center' :
                            (mediaType === 'isMobile') ? 'start' : 'center',
                            gap:'20px',
                            justifyContent:'space-between',
                            width:(mediaType === 'isDesktop') ? '30%' :
                            (mediaType === 'isTablet') ? '60%' :
                            (mediaType === 'isMobile') ? '80%' : '80%',
                            margin: (mediaType === 'isDesktop') ? '200px 50px 0 0' :
                                    (mediaType === 'isTablet') ? '0 0 0 0' :
                                    (mediaType === 'isMobile') ? '200px 0 0 0' : '200px 50px 0 0' ,
                        }}
                    >
                        <Box
                            sx = {{
                                width:(mediaType === 'isDesktop') ? '80%' :
                                (mediaType === 'isTablet') ? '65%' :
                                (mediaType === 'isMobile') ? '100%' : '100%',
                                display:'flex',
                                flexDirection:'row',
                                alignItems:'center',
                                justifyContent:'space-between',
                            }}
                        >
                            <Typography
                            >
                                {t("tel")}
                            </Typography>
                            <Typography sx = {{display:'none'}}>
                                {"۰۹۳۵۳۱۲۴۵۶۵"}
                            </Typography>
                        </Box>
                        <Box
                            sx = {{
                                width:(mediaType === 'isDesktop') ? '80%' :
                                (mediaType === 'isTablet') ? '65%' :
                                (mediaType === 'isMobile') ? '100%' : '100%',
                                display:'flex',
                                flexDirection:'row',
                                alignItems:'center',
                                justifyContent:'space-between',
                            }}
                        >
                            <Typography
                            >
                                {t("email")}
                            </Typography>
                            <a href="mailto:info@rayanwebhub.ir" style = {{color:'#fff', textDecoration:'none'}}>
                                <Typography>
                                    {"info@rayanwebhub.ir"}
                                </Typography>
                            </a>
                        </Box>
                        <Box
                            sx = {{
                                width:(mediaType === 'isDesktop') ? '80%' :
                                (mediaType === 'isTablet') ? '65%' :
                                (mediaType === 'isMobile') ? '100%' : '100%',
                                display:'flex',
                                flexDirection:'row',
                                alignItems:'center',
                                justifyContent:'space-between',
                            }}
                        >
                            <Typography
                            >
                                {t("website")}
                            </Typography>
                            <Link to='/' style = {{color:'#fff', textDecoration:'none'}}>
                                <Typography>
                                    {"www.rayanwebhub.ir"}
                                </Typography>
                            </Link>
                        </Box>
                        <Box
                            sx = {{
                                width:(mediaType === 'isDesktop') ? '60%' :
                                      (mediaType === 'isDesktop') ? '60%' :
                                      (mediaType === 'isMobile') ? '100%' : '60%',
                                margin:'30px auto',                                
                            }}
                        >
                            <SocialMediaIconSet />
                        </Box>
                    </Box>
                    <Box
                        sx = {{
                            order: (mediaType !== 'isMobile') ? 2 : 1,
                        }}
                    >
                        <NavBar mediaType = {mediaType} forFooter={true}/>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}