import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Box  from '@mui/material/Box';
import Banner from '../components/Banner';
import Typography from '@mui/material/Typography';
import BlogPostsGallery from '../components/BlogPostsGallery';
import ContactSection from '../components/ContactSection';
import blogHero from './../images/blogHero.jpg';
import blogingImgUrl from './../images/blogging.jpg';
import banner4 from './../images/banner4.jpg';
import BlogPostCategories from '../components/BlogPostCategories';

export default function WeblogPage({mediaType}) {
    const {t,i18n} = useTranslation(['blogGeneral']);
    return (
        <Container sx = {{display:'flex',flexDirection:'column',alignItems:'center',gap:'100px',marginTop:'100px'}}>
            <Typography 
                variant="h5"
                sx = {{
                    alignSelf:'start',
                }}
            >
                {t('BlogPageTitle')}
            </Typography>
            <Typography 
                variant="body1"
                sx = {{
                    fontSize: '20px',
                    textAlign:'justify',
                    lineHeight:'50px',
                    alignSelf:'start',
                    padding: (mediaType === 'isDesktop') ? '0' :
                             (mediaType === 'isTablet') ? '0 30px' :
                             (mediaType === 'isMobile') ? '0 10px' : '0'
                }}
            >
                {t('BlogPageDescription')}
            </Typography>
            <Box
                sx = {{
                    width:'100vw',
                }}
            >
                <Banner mediaType = {mediaType}                         
                        imgUrl = {blogHero} 
                        bWidth={'100%'}
                        bHeight={'100vh'}
                        title = {{txt: '', pos:{x:'30%',y:'40%'}}} 
                        subtitle={{txt: '',pos:{x:'30%',y:'60%'}}}
                        imgLoc={(mediaType === 'isDesktop') ? 'center' : 'right'}
                        imgSize = {(mediaType === 'isDesktop') ? 'contain' : 'contain'}
                />
            </Box>
            <BlogPostCategories mediaType={mediaType} />
            <Box
                sx = {{
                    width:'100vw',
                }}
            >
                <Banner mediaType = {mediaType}                         
                        imgUrl = {blogingImgUrl} 
                        bWidth={'100%'}
                        bHeight={'60vh'}
                        title = {{txt: '', pos:{x:'30%',y:'40%'}}} 
                        subtitle={{txt: '',pos:{x:'30%',y:'60%'}}}
                        imgLoc={(mediaType === 'isDesktop') ? 'center' : 'right'}
                        imgSize = {(mediaType === 'isDesktop') ? 'contain' : 'cover'}
                />
            </Box>
            <Typography 
                variant="h5"
                sx = {{
                    alignSelf:'start',
                }}
            >
                {t('HomePageTitle')}
            </Typography>
            <BlogPostsGallery mediaType={mediaType}/>


            <Box
                sx = {{
                    width:'100vw',
                }}
            >
                <Banner mediaType = {mediaType}                         
                        imgUrl = {banner4} 
                        title = {{txt: '', pos:{x:'30%',y:'40%'}}} 
                        subtitle={{txt: '',pos:{x:'30%',y:'60%'}}}
                        imgLoc={(mediaType === 'isDesktop') ? 'left' : 'center'}
                        imgSize = {(mediaType === 'isDesktop') ? 'cover' : 'contain'}
                />
            </Box>
            <ContactSection mediaType={mediaType} />
            <div ><br/><br/><br/><br/></div>
        </Container>

    )
}
