import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box'
import OrderForm from "./OrderForm";

export default function OrdersSection({mediaType}) {
    const {t,i18n} = useTranslation(['order'])
    return (
        <Box
            sx = {{
                width:'100%',
                display:'flex',
                flexDirection:'column',
                gap:'30px',
            }}
        >
            <Typography
                variant="h5"
            >
                {t("sectionTitle")}
            </Typography>
            <Typography>
                {t("sectionCaptio")}
            </Typography>
            <OrderForm mediaType={mediaType} />
        </Box>
    )
}