import { useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {IR,GB} from 'country-flag-icons/react/3x2';

export default function LanguageSelector({mediaType}) {
    const {i18n} = useTranslation();

    useEffect(() => {
        document.body.dir = i18n.dir();
        
    },[i18n,i18n.language]);
    const languages = [
        {code:'en',name:'English',element:<GB />},
        {code:'fa',name:'Farsi',element:<IR />},
    ]
    const styles = {
      flagDesktop: {
        width:'50px'
      },
      flagTablet: {
        width:'60px'
      },
      flagMobile: {
        width:'50px'
      },
      flgaBoxTablet: {
        width:'50px'
      }
    }
    const langItems = languages.map((item,index) => {
      return (
          <MenuItem 
            key = {index}
            value={item.code}
            sx = {
              (mediaType === 'isDesktop') ? 
                styles.flagDesktop : 
                (mediaType === 'isTablet') ?
                  styles.flagTablet
                  :
                  styles.flagMobile
            }
          > 
            {item.element}
          </MenuItem>
      )
    })
    const changeLanguage = (lang) => {   
        // setSelectedLang(lang);
        // i18n.changeLanguage(lang);
        i18n.changeLanguage('en');
    }
    return (
        <Box
          sx = {
            (mediaType === 'isMobile') ? 
              {
                position:'fixed',
                top:'10px',
                left:'10px',
              } : (mediaType === 'isTablet') ? 
              {
                position:'fixed',
                top:'30px',
                left:'30px',
              } : {}
          }
        >
        <FormControl 
          variant="standard"
          sx = {
            {display:'none'}
            // (mediaType === 'isTablet') ? styles.flgaBoxTablet : {}
          }
        >
          <Select
            id="simple-select"
            label="Language"
            value = {i18n.language}
            defaultValue="en"
            onChange={(e) => changeLanguage(e.target.value)}
          >
            {langItems}
          </Select>
        </FormControl>
      </Box>
    )
}