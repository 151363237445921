import Container from '@mui/material/Container';
import Box  from '@mui/material/Box';

import Banner from '../components/Banner';
import ContactSection from '../components/ContactSection';
import contactBannerImgUrl from './../images/contact.jpg';

export default function ContactPage({mediaType}) {
    return (
        <Container sx = {{display:'flex',flexDirection:'column',alignItems:'center',gap:'100px',marginTop:'100px'}}>
            <Box
                sx = {{
                    width:'100vw',
                }}
            >
                <Banner mediaType = {mediaType}                         
                        imgUrl = {contactBannerImgUrl} 
                        title = {{txt: '', pos:{x:'30%',y:'40%'}}} 
                        subtitle={{txt: '',pos:{x:'30%',y:'60%'}}}
                        imgLoc={(mediaType === 'isDesktop') ? 'left' : 'center'}
                        imgSize = {(mediaType === 'isDesktop') ? 'cover' : 'contain'}
                />
            </Box>
            <ContactSection mediaType={mediaType} />
            <div ><br/><br/><br/><br/></div>
        </Container>

    )
}
