import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';

import samplecompany1 from './../images/samples/samplecompany1.png'
import samplecompany2 from './../images/samples/samplecompany2.png'
import samplecompany11 from './../images/samples/samplecompany11.png'
import firstpersian from './../images/samples/firstpersian.png'
import sampleshop1 from './../images/samples/sampleshop1.png'

export default function SamplesGallery({mediaType}) {
    const {t,i18n} = useTranslation(['sample'])
  return (
    <Box
        sx = {{
            width:'100%',
            margin:'0 auto',
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            gap:'50px'
        }}
    >
        <Typography
            variant='h5'
            sx = {{
                alignSelf:'start',
            }}
        >
            {t('title')}
        </Typography>
        <ImageList 
            sx={{ 
                width: '80%', 
                height: '60vh',
            }} 
            cols={(mediaType === 'isDesktop') ? 2 : 
                    (mediaType === 'isTablet') ? 2 : 1
                 }
        >
        {samplesData.map((item) => (
            // <a key={item.title} href = {`${process.env.REACT_APP_LINKS_PROD}/samples/${item.url}/index.html`} style = {{textDecoration:'none',color:'#000'}} >
            <a key={item.title} href = {item.url} target="_blank" style = {{textDecoration:'none',color:'#000'}} >
              <ImageListItem sx = {{margin:'10px',cursor:'pointer'}}>
                <img                
                    srcSet={`${item.imgUrl}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    src={`${item.imgUrl}?w=248&fit=crop&auto=format`}
                    alt={item.title}
                    loading="lazy"
                />
                <ImageListItemBar
                    sx = {{textAlign:'center',margin:'10px 0 20px 0'}}
                    title={t(item.title)}
                    subtitle={<span>{t(item.subtitle)}</span>}
                    position="below"
                />
              
              </ImageListItem>
            </a>
        ))}
        </ImageList>

    </Box>
  );
}


const samplesData = [
    {
        title: 'company1.0.title',
        subtitle: 'company1.1.subTitle',
        imgUrl: samplecompany11,
        url:'https://samplecompany11.pages.dev/'
    },
    {
        title: 'company2.0.title',
        subtitle: 'company2.1.subTitle',
        imgUrl: samplecompany2,
        url:'https://samplecompany2.pages.dev/'
    },
    {
        title: 'company3.0.title',
        subtitle: 'company3.1.subTitle',
        imgUrl: samplecompany1,
        url:'https://samplecompany1.pages.dev/'
    },
    {
        title: 'company4.0.title',
        subtitle: 'company4.1.subTitle',
        imgUrl: firstpersian,
        url:'https://firstpersian.pages.dev/'
    },
    {
        title: 'shop1.0.title',
        subtitle: 'shop1.1.subTitle',
        imgUrl: sampleshop1,
        url:'https://sampleshop1.pages.dev/'
    },
]
