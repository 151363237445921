import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";
import { Box } from '@mui/material';


import { useSpring, animated } from '@react-spring/web';


export default function Banner({mediaType,imgUrl,bWidth,bHeight,title,subtitle,imgLoc,imgSize,url}) {

    const {t,i18n} = useTranslation(['header']);
    const [springs,api] = useSpring(() => ({
        from: {rotateZ :0}
    }));
    const bannerWidth = (bWidth === undefined) ? '100%' : bWidth;
    const bannerHeight = (bHeight === undefined) ? '50vh' : bHeight;
    const titlePos = title.pos;
    const subTitlePos = subtitle.pos;
    const styels = {
        paperContainer : {
            width:bannerWidth,
            backgroundImage:`url(${imgUrl})`,
            backgroundSize:imgSize.toString(),
            backgroundPosition:imgLoc.toString(),
            backgroundRepeat:'no-repeat',
            height:bannerHeight,
        }
    }
    
    const handleHover = () => {
        api.start({
            from: {
                rotateZ:0,
            },
            to: {
                rotateZ:30
            }
        })
    }
    return (
        <Paper 
            sx = {styels.paperContainer}
        >
            <Box
                
                sx = {{
                    width:'100%',
                    height: bannerHeight,
                    position:'relative',
                }}
                component={'div'}
            >
                <a href={url}>
                    <Typography
                        sx = {{
                            fontSize : (mediaType === 'isDesktop') ? "3vw" : 
                                        (mediaType === 'isTablet') ? "5vw" : 
                                            (mediaType === 'isMobile') ? "8vw"  : ''
                                ,
                            position:'absolute',
                            top:titlePos.y,
                            left:titlePos.x,
                            fontFamily: (i18n.language === 'fa') ? 'Vazirmatn' : 'Roboto',
                            cursor:'pointer',
                            color:'#000'
                        }}
                    >
                        {t(`${title.txt}`)}
                    </Typography>

                </a>
                <Typography
                    // variant = 'h5'
                    sx = {{
                        fontSize : (mediaType === 'isDesktop') ? "1.3vw" : 
                                    (mediaType === 'isTablet') ? "3vw" : 
                                        (mediaType === 'isMobile') ? "4vw"  : ''
                              ,
                        position:'absolute',
                        top:subTitlePos.y,
                        left:subTitlePos.x,
                        fontFamily: (i18n.language === 'fa') ? 'Vazirmatn' : 'Roboto',
                        cursor:'pointer'
                     }}
                >
                    {t(`${subtitle.txt}`)}
                </Typography>
            </Box>

        </Paper>            
    )
}