import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next"

import SamplesGallery from "../components/SamplesGallery";
import FillingBox from "../components/FillingBox";


export default function AboutPage({mediaType}) {
    const {t,i18n} = useTranslation(['about','sample']);
    return (
        <Container sx = {{display:'flex',flexDirection:'column',alignItems:'center',gap:'100px',marginTop:'100px'}}>
            <Typography 
                variant="h5"
                sx = {{
                    alignSelf:'start'
                }}
            >
                {t('title')}
            </Typography>
            <Typography 
                variant="body1"
                sx = {{
                    fontSize: '20px',
                    textAlign:'justify',
                    lineHeight:'50px',
                    padding: (mediaType === 'isDesktop') ? '0' :
                             (mediaType === 'isTablet') ? '0 30px' :
                             (mediaType === 'isMobile') ? '0 10px' : '0'
                }}
            >
                {t('context')}
            </Typography>

            <SamplesGallery mediaType = {mediaType} />
            <div ><br/><br/><br/><br/></div>



        </Container>

    )
}
