import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import {getData} from '../connect'

export default function Comments({mediaType}) {
    const {t,i18n} = useTranslation(['comment']);
    const [commentsArray,setCommentsArray] = useState([]);
    useEffect(() => {
        getData('http://127.0.0.1:4000/api/comments')
            .then((result) => {
                const newComments = result.result.map((item) => {
                    return({
                        user:item['fullname'],
                        comment: item['msg']
                    })
                })
                setCommentsArray(newComments);
            })
            .catch((e) => {
                console.log(e)
            })
    },[])
    const commentsArray1 = [
        {
            user:"WebMaster88",
            comment:"متن بسیار جامع و کاملی بود! به نظرم ویژگی‌های مهم وب‌سایت شرکتی را به نحوی خوبی توضیح داده و از جمله مواردی که باید حتماً در نظر گرفته شوند، یاد کرده است. با توجه به تجربه‌ی نویسنده، این متن اعتبار بسیاری دارد و می‌تواند به کسب‌وکارهایی که می‌خواهند وب‌سایت شرکتی خود را راه‌اندازی کنند، راهنمایی کاربردی باشد.",
        },
        {
            user:"TechEnthusiast21",
            comment:"عالی! من به شخصه از نوع طرح آموزشی متن خوشم می‌آید. این متن به طور واضح و قابل فهم ویژگی‌های ضروری برای یک وب‌سایت شرکتی را توضیح داده و به کاربران کمک می‌کند تا بتوانند از این ویژگی‌ها به بهترین شکل ممکن استفاده کنند. نوشتار روان و مرتبط با عنوان بوده و به راحتی قابل فهم است.",
        },
        {
            user:"DigitalWizard",
            comment:"من با نظر دوستان موافقم که این متن بسیار مفید و جامع است. اما به نظرم می‌توانست از یک مثال یا داستان کوتاه برای هر ویژگی که توضیح داده شده استفاده کند تا مطالب را جذاب‌تر کند و کاربران را بیشتر به خواندن ترغیب کند. به هر حال، این متن یک منبع مفید و ارزشمند برای افرادی است که در زمینه طراحی وب‌سایت فعالیت می‌کنند.",
        },
    ]
    return (
        <Box
            sx = {{
                mb: 30
            }}
        >
            <Typography
                variant="h5"
                sx = {{my:8,fontWeight:'bold'}}
            >
                {t('title')}
            </Typography>
            {
                commentsArray.map((item,index) => {
                    return (
                        <Box
                            sx = {{
                                my:5,
                            }}
                            key={index}
                        >
                            <Typography
                                variant="body1"
                                sx = {{
                                    fontWeight:'bold',
                                    fontSize:'16px',
                                    my:3
                                }}
                            >
                                {item.user}
                            </Typography>
                            <Typography
                                sx = {{
                                    textAlign:'justify',
                                    fontSize:(mediaType === 'isDesktop') ? '16px' : '16px',
                                    lineHeight: (mediaType === 'isDesktop') ? '40px' : '30px',
                                }}
                            >{item.comment}</Typography>
                        </Box>
                    )
                })
            }
        </Box>
    )
}