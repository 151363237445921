import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Box  from '@mui/material/Box';
import Typography  from '@mui/material/Typography';
import Banner from '../components/Banner';
import SamplesGallery from '../components/SamplesGallery';
import OrdersSection from '../components/OrdersSection'
import ContactSection from '../components/ContactSection';
import heroImageUrl from './../images/companySample1.jpg';
import banner2 from './../images/banner2.jpg';
import banner4 from './../images/banner4.jpg';

export default function ProductPage({mediaType}) {
    const {t,i18n} = useTranslation(['products']);

    return (
        <Container sx = {{display:'flex',flexDirection:'column',alignItems:'center',gap:'100px',marginTop:'100px'}}>
            <Typography
                variant='h5'
                sx = {{
                    alignSelf:'start',
                }}
            >
                {t('company.0.title')}
            </Typography>
            <Box
                sx = {{
                    width:'100vw',
                }}
            >
                <Banner mediaType = {mediaType}                         
                        imgUrl = {heroImageUrl} 
                        title = {{txt: '', pos:{x:'30%',y:'40%'}}} 
                        subtitle={{txt: '',pos:{x:'30%',y:'60%'}}}
                        imgLoc={(mediaType === 'isDesktop') ? 'center' : 'right'}
                        imgSize = {(mediaType === 'isDesktop') ? 'contain' : 'contain'}
                />
            </Box>
            <Typography
                sx = {{
                    varint:'body1',
                    alignSelf:'start',
                    textAlign:'justify',
                    fontSize: (mediaType === 'isDesltop') ? '20px' : 
                              (mediaType === 'isTablet') ? '24px' : '16px',
                    lineHeight: (mediaType === 'isDesltop') ? '60px' : 
                                (mediaType === 'isTablet') ? '60px' : '40px',
                    px:3
                }}
            >
                {t('company.2.context')}
            </Typography>
            <SamplesGallery mediaType = {mediaType} />
            <Box
                sx = {{
                    width:'100vw',
                }}
            >
                <Banner mediaType = {mediaType}                         
                        imgUrl = {banner2} 
                        title = {{txt: '', pos:{x:'30%',y:'40%'}}} 
                        subtitle={{txt: '',pos:{x:'30%',y:'60%'}}}
                        imgLoc={(mediaType === 'isDesktop') ? 'left' : 'right'}
                        imgSize = {(mediaType === 'isDesktop') ? 'cover' : 'cover'}
                />
            </Box>
            <OrdersSection mediaType={mediaType} />
            <Box
                sx = {{
                    width:'100vw',
                }}
            >
                <Banner mediaType = {mediaType}                         
                        imgUrl = {banner4} 
                        title = {{txt: '', pos:{x:'30%',y:'40%'}}} 
                        subtitle={{txt: '',pos:{x:'30%',y:'60%'}}}
                        imgLoc={(mediaType === 'isDesktop') ? 'left' : 'center'}
                        imgSize = {(mediaType === 'isDesktop') ? 'cover' : 'contain'}
                />
            </Box>
            <ContactSection mediaType={mediaType} />
            <div ><br/><br/><br/><br/></div>
        </Container>

    )
}
