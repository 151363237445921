function reFormatDate(date0) {
    const year = `${date0.getFullYear()}`;
    const month = date0.getMonth() < 10 ? `0${date0.getMonth()}` : `0${date0.getMonth()}`;
    const day = date0.getDate() < 10 ? `0${date0.getDate()}` : `0${date0.getDate()}`;
    return `${year}-${month}-${day}`
}

function reFormatTime(date0) {
    const hour = (date0.getHours() < 10) ? `0${date0.getHours()}` : `${date0.getHours()}`
    const minutes = (date0.getMinutes() < 10) ? `0${date0.getMinutes()}` : `${date0.getMinutes()}`
    const seconds = (date0.getSeconds() < 10) ? `0${date0.getSeconds()}` : `${date0.getSeconds()}`
    return `${hour}:${minutes}:${seconds}`
}



export {reFormatDate,reFormatTime}