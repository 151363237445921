import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetctor from 'i18next-browser-languagedetector';


i18n
    .use(Backend)
    .use(LanguageDetctor)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        // debug:true
    })


export default i18n;