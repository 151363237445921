import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../axiosConfig';
import { useForm, Controller } from 'react-hook-form';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Button, Typography } from '@mui/material';

import { postData } from '../connect';
import { reFormatDate,reFormatTime } from '../utlility';


export default function OrderForm({mediaType}) {
    const [successMsg,setSuccessMsg] = useState('');
    const {t,i18n} = useTranslation(['order']);
    const {
      register,
      formState: {errors},
      clearErrors,
      control,
      reset,
      handleSubmit
    } = useForm();

    const onSubmit = (data) => {
      const data1 = {
        fullname: `'${data.fullname.toString()}'`,
        email: `'${data.email.toString()}'`,
        tel: `'${data.tel.toString()}'`,
        domainrequired: `${data.domainrequired}`,
        hostrequired: `${data.hostrequired}`,
        websitetype: `'${data.siteType}'`,
        dateofregistration: reFormatDate(new Date()),
        timeofregistration: reFormatTime(new Date()),
        notes: `'${data.notes.toString()}'`,
        status: `'${'just registered'}'`
    }
      axiosInstance.post('order.php',data1, 
        {
          headers: {'Content-Type': 'application/json'}
        }
      )
      .then((result) => {
        reset();
        setSuccessMsg('success');
        console.log(result);
      })
      .catch((e) => {
        setSuccessMsg('failed');
        console.log(e);
      })
      // postData('http://127.0.0.1:4000/api/order',data1)
      //   .then((result) => {
      //     reset();
      //     setSuccessMsg('success');
      //     console.log(result);
      //   })
      //   .catch((e) => {
      //     setSuccessMsg('failed');
      //     console.log(e);
      //   })
    }
    const clearMsg = () => {
      clearErrors();
      setSuccessMsg('');
    }
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{        
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        gap:'50px',
        width:(mediaType === 'isDesktop') ? '60%':
              (mediaType === 'isTablet') ? '65%':
              (mediaType === 'isMobile') ? '100%': '100%',
        margin:'0 auto',
        direction:i18n.dir()
      }}

      noValidate
      // autoComplete="off"
    >
      <Box
        sx = {{
            display:'flex',
            flexDirection:(mediaType === 'isDesktop') ? 'row' : 'column',
            gap:'10px',
            width:'100%',
        }}
      >
        <TextField
        //   error
          id="fullname"
          {...register('fullname',{required:true, minLength: 5, pattern: /\b[A-Za-z][\w]*/i})}
          // name = "fullname"
          label={t('name')}
          placeholder={t('name')}
          onChange={() => clearMsg()}
          sx = {{
            '& .MuiInputLabel-root':{
              transformOrigin:i18n.dir() === 'rtl'? 'top right' : 'top left',
              right:'31px'
            },
            '& .MuiInputBase-root': {
              direction:i18n.dir()
            }
          }}
        />
        
        {(errors.fullname?.type !== undefined) && (
          <p style = {{color:'red'}}>{t('fullNameRequired',{ns:'common'})}</p>
        )}
        <TextField
        //   error
          id="email"
          // name="email"
          {...register('email',{required: true, pattern:/[\w]+@[\w]+.[\w]{3}/i })}
          label={t("email")}
          placeholder="email@example.com"
          helperText=""
          onChange={() => clearMsg()}
          sx = {{
            '& .MuiInputLabel-root':{
              transformOrigin:i18n.dir() === 'rtl'? 'top right' : 'top left',
              right:'31px'
            },
          }}
        />
        {(errors.email?.type !== undefined) && (
            <p style = {{color:'red'}}>{t('emailRequired',{ns:'common'})}</p>
        )}
        <TextField
        //   error
          id="tel"
          // name="tel"
          {...register("tel")}{...register('tel',{required: true, pattern:/[\d]{4,8}/})}
          label={t("tel")}
          placeholder="000 000 0000"
          helperText=""
          onChange={() => clearMsg()}
          sx = {{
            '& .MuiInputLabel-root':{
              transformOrigin:i18n.dir() === 'rtl'? 'top right' : 'top left',
              right:'31px'
            },
          }}
        />

        {(errors.tel?.type !== undefined) && (
            <p style = {{color:'red'}}>{t('telRequired',{ns:'common'})}</p>
        )}
      </Box>
      <Box
        sx = {{
          width:'100%'
            
        }}
      >
        <FormControl
          sx = {{
                  display:'flex' ,
                  flexDirection:'row',
                  justifyContent:'space-between',
                  alignItems:'center',
                  width: (mediaType === 'isDesktop') ? 600 :
                         (mediaType === 'isTablet') ? 500 :
                         (mediaType === 'isMobile') ? 280 : 200,
                  margin:'0 auto',
                }}
        >
          <FormLabel 
            sx = {{fontSize:(mediaType === 'isDesktop') ? '1rem' :
                            (mediaType === 'isTablet') ? '1rem' :
                            (mediaType === 'isMobile') ? '0.8rem' : '0.5rem'
                  }}
          >
            {t("domainRequired")}
          </FormLabel>
          <Controller
            name = "domainrequired"
            control={control}
            defaultValue={1}
            render={({field}) => (
              <RadioGroup {...field} row>
                <FormControlLabel 
                      value={1}
                      control={<Radio />} 
                      label={
                          <Typography 
                              sx = {{fontSize:(mediaType === 'isDesktop') ? '0.9rem' :
                                              (mediaType === 'isTablet') ? '0.8rem' :
                                              (mediaType === 'isMobile') ? '0.7rem' : '0.6rem'
                                  }}
                          >
                            {t("yes")}
                          </Typography>}
                />
                <FormControlLabel 
                      value={0}
                      control={<Radio />} 
                      label={
                          <Typography 
                              sx = {{fontSize:(mediaType === 'isDesktop') ? '0.9rem' :
                                              (mediaType === 'isTablet') ? '0.8rem' :
                                              (mediaType === 'isMobile') ? '0.7rem' : '0.6rem'
                                  }}
                          >
                            {t("no")}
                          </Typography>}
                />
              </RadioGroup>
            )
            }
          />
        </FormControl>
        <FormControl
          sx = {{
                  display:'flex' ,
                  flexDirection:'row',
                  justifyContent:'space-between',
                  alignItems:'center',
                  width: (mediaType === 'isDesktop') ? 600 :
                         (mediaType === 'isTablet') ? 500 :
                         (mediaType === 'isMobile') ? 280 : 200,
                  margin:'0 auto'}}
        >
        <FormLabel
            sx = {{fontSize:(mediaType === 'isDesktop') ? '1rem' :
            (mediaType === 'isTablet') ? '1rem' :
            (mediaType === 'isMobile') ? '0.8rem' : '0.5rem'
  }}
        >
          {t("hostRequired")}
        </FormLabel>
          <Controller
            name = "hostrequired"
            control={control}
            defaultValue={1}
            render={({field}) => (
              <RadioGroup {...field} row>
                <FormControlLabel 
                      value={1} 
                      control={<Radio />} 
                      label={
                          <Typography 
                              sx = {{fontSize:(mediaType === 'isDesktop') ? '0.9rem' :
                                              (mediaType === 'isTablet') ? '0.8rem' :
                                              (mediaType === 'isMobile') ? '0.7rem' : '0.6rem'
                                  }}
                          >
                            {t("yes")}
                          </Typography>}
                />
                <FormControlLabel 
                      value={0} 
                      control={<Radio />} 
                      label={
                          <Typography 
                              sx = {{fontSize:(mediaType === 'isDesktop') ? '0.9rem' :
                                              (mediaType === 'isTablet') ? '0.8rem' :
                                              (mediaType === 'isMobile') ? '0.7rem' : '0.6rem'
                                  }}
                          >
                            {t("no")}
                          </Typography>}
                />
              </RadioGroup>

            )}
          />
        </FormControl>
      </Box>

    
      <Box sx={{ minWidth: '100%'}}>
        <FormControl 
          sx = {{
            '& .MuiFormLabel-root':{
              transformOrigin:i18n.dir() === 'rtl' ? 'top right' : 'top left',
              right:'31px',
            },
          }}
          fullWidth
        >
          <InputLabel>{t('siteType')}</InputLabel>
          <Controller 
            name="siteType"
            control={control}
            defaultValue={"company"}
            render={({field}) => (
              <Select {...field}
                // value={siteType}
                label={t('siteType')}
                // onChange={handleChange}
              >
                <MenuItem value={'company'} >{t('company')}</MenuItem>
                <MenuItem value={'shopping'} >{t('shopping')}</MenuItem>
                <MenuItem value={'weblog'} >{t('weblog')}</MenuItem>
              </Select>
            )}

          />          
        </FormControl>
      </Box>
      <Box sx = {{flex:'1',display:'flex',flexDirection:'column',alignItems:'stretch',}}>
        <TextField
            //   error
          label={t('notes')}
          {...register('notes')}
          multiline
          rows = {4}
          placeholder={t('notes')}
          sx = {{
                '& .MuiInputLabel-root': {
                  transformOrigin:i18n.dir() === 'rtl'? 'top right' : 'top left',
                  right:'31px',
                },
                width:(mediaType === 'isDesktop') ? 700 :
                      (mediaType === 'isTablet') ? 500 :
                      (mediaType === 'isMobile') ? 300 : 200,               
               }} 

        />
      </Box>
      <Box sx = {{display:'flex',flexDirection:'row',gap:'30px'}}>
        <Button type="submit"  variant='contained'>{t('submit',{ns:'order'})}</Button>
        <Button type="reset" variant='contained'>{t('clear',{ns:'order'})}</Button>
      </Box>
    </Box>
  );
}
