import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../axiosConfig';
import {useForm,Controller} from 'react-hook-form';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { postData } from '../connect';
import { reFormatDate,reFormatTime } from '../utlility';




export default function ContactForm({mediaType}) {
    const [successMsg,setSuccessMsg] = useState('');
    const {t,i18n} = useTranslation(['contact']);
    const {
        register,
        formState: {errors},
        clearErrors,
        control,
        reset,
        handleSubmit
    } = useForm();

    const onSubmit = (data) => {
        const data1 = {
            fullname: `'${data.fullname.toString()}'`,
            email: `'${data.email.toString()}'`,
            tel: `'${data.tel.toString()}'`,
            msg: `'${data.msg.toString()}'`,
            dateofregistration: reFormatDate(new Date()),
            timeofregistration: reFormatTime(new Date()),
            notes: `' '`,
            status: `'${'just registered'}'`
        }
        axiosInstance.post(
            'contact.php', data1,
            {
                headers: {'Content-Type': 'application/json'}
            }
        )
        .then((response) =>  {
            reset();
            setSuccessMsg('success');
       })
        .catch((e) => {
            setSuccessMsg('failed');
            console.log(e);
       })

    }

    const clearMsg = () => {
        clearErrors();
        setSuccessMsg('');
    }

  return (
    <Box
      component="form"      
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        gap:'50px',
        width:'100%',
        margin:'0 auto',
        direction:i18n.dir()
      }}

      noValidate
    //   autoComplete="off"
    >
        <Box
            sx = {{
                display:'flex',
                flexDirection:(mediaType === 'isDesktop') ? 'row':
                              (mediaType === 'isTablet') ? 'column':
                              (mediaType === 'isMobile') ? 'column': 'column',
                alignItems:'center',
                gap:'50px',
                width:(mediaType === 'isDesktop') ? '80%':
                    (mediaType === 'isTablet') ? '100%':
                    (mediaType === 'isMobile') ? '100%': '100%',
            }}
        >
            <Box
                sx = {{
                    display:'flex',
                    flexDirection:(mediaType === 'isDesktop') ? 'column' : 'column',
                    gap:'10px',
                    width:(mediaType === 'isDesktop') ? '50%':
                    (mediaType === 'isTablet') ? '65%':
                    (mediaType === 'isMobile') ? '80%': '100%',
                }}
            >
                <TextField
                    //   error
                    id="fullname"
                    // name = "fullname"
                    {...register('fullname',{required: true, minLength: 5, pattern:/\b[A-Za-z][\w]*/i })}
                    label={t('fullName')}
                    placeholder={t('fullName')}
                    onChange={() => clearMsg()}
                    sx = {{
                        '& .MuiInputLabel-root': {
                        transformOrigin:i18n.dir() === 'rtl' ? 'top right' : 'top left',
                        right:'31px',
                        }}}
                />
                {(errors.fullname?.type !== undefined) && (
                    <p style = {{color:'red'}}>{t('fullNameRequired',{ns:'common'})}</p>
                )}
                <TextField
                //   error
                id="email"
                // name="email"
                {...register('email',{required: true, pattern:/[\w]+@[\w]+.[\w]{3}/i })}
                label={t("email")}
                placeholder="email@example.com"
                helperText=""
                onChange={() => clearMsg()}
                sx = {{
                    '& .MuiInputLabel-root': {
                    transformOrigin:i18n.dir() === 'rtl' ? 'top right' : 'top left',
                    right:'31px',
                    }}}
                />
                {(errors.email?.type !== undefined) && (
                    <p style = {{color:'red'}}>{t('emailRequired',{ns:'common'})}</p>
                )}

                <TextField
                    //   error
                    id="tel"
                    // name="tel"
                    {...register('tel',{required: true, pattern:/[\d]{4,8}/})}
                    label={t("tel")}
                    placeholder="000 000 0000"
                    helperText=""
                    onChange={() => clearMsg()}
                    sx = {{
                        '& .MuiInputLabel-root': {
                        transformOrigin:i18n.dir() === 'rtl' ? 'top right' : 'top left',
                        right:'31px',
                        }}}
                />

                {(errors.tel?.type !== undefined) && (
                    <p style = {{color:'red'}}>{t('telRequired',{ns:'common'})}</p>
                )}
            </Box>
            <Box 
                sx = {{
                        flex:'1',
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center',
                        width:(mediaType === 'isDesktop') ? '50%' :
                        (mediaType === 'isTablet') ? '65%' :
                        (mediaType === 'isMobile') ? '80%' : '80%'

                      }}
            >
                <TextField
                        //   error
                    // name = "message"
                    {...register('msg',{required: true})}
                    label={t('message')}
                    multiline
                    rows = {4}
                    placeholder={t('message')}
                    fullWidth  
                    onChange={() => clearMsg()}
                    sx = {{
                        '& .MuiInputLabel-root': {
                        transformOrigin:i18n.dir() === 'rtl' ? 'top right' : 'top left',
                        right:'31px',
                        }}}
                />
                {(errors.message?.type !== undefined) && (
                    <p style = {{color:'red'}}>{t('messageRequired',{ns:'common'})}</p>
                )}
            </Box>
        </Box>
        <Box
            sx = {{
                display:'flex',
                flexDirection:'row',
                gap:'30px'
            }}
        >
            <Button variant="contained" color="primary" type="submit">
                {t("send")}
            </Button>
            <Button variant="contained" color="primary" type="reset">
                {t("reset")}
            </Button>
        </Box>
        <Typography style = {{color: successMsg === 'success' ? "green": 'red'}}>{t(`${successMsg}`,['contact'])}</Typography>        
        <Box
            sx = {{
                display:'flex',
                flexDirection: (mediaType === 'isDesktop') ? 'row' :
                (mediaType === 'isTablet') ? 'column' :
                (mediaType === 'isMobile') ? 'column' : 'column',
                alignItems: (mediaType === 'isDesktop') ? 'center' :
                (mediaType === 'isTablet') ? 'center' :
                (mediaType === 'isMobile') ? 'start' : 'center',
                gap:'30px',
                justifyContent:'space-between',
                width:(mediaType === 'isDesktop') ? '100%' :
                (mediaType === 'isTablet') ? '60%' :
                (mediaType === 'isMobile') ? '80%' : '80%',
                marginTop:'50px'
            }}
        >
            <Box
                sx = {{
                    width:(mediaType === 'isDesktop') ? '30%' :
                    (mediaType === 'isTablet') ? '65%' :
                    (mediaType === 'isMobile') ? '100%' : '100%',
                    display:'flex',
                    flexDirection:'row',
                    alignItems:'center',
                    justifyContent:'space-between',
                }}
            >
                <Typography
                >
                    {t("tel")}
                </Typography>
                <Typography>
                    {"۰۹۳۵۳۱۲۴۵۶۵"}
                </Typography>
            </Box>
            <Box
                sx = {{
                    width:(mediaType === 'isDesktop') ? '30%' :
                    (mediaType === 'isTablet') ? '65%' :
                    (mediaType === 'isMobile') ? '100%' : '100%',
                    display:'flex',
                    flexDirection:'row',
                    alignItems:'center',
                    justifyContent:'space-between',
                }}
            >
                <Typography
                >
                    {t("email")}
                </Typography>
                <Typography>
                    {"info@rayanwebhub.ir"}
                </Typography>
            </Box>
            <Box
                sx = {{
                    width:(mediaType === 'isDesktop') ? '30%' :
                    (mediaType === 'isTablet') ? '65%' :
                    (mediaType === 'isMobile') ? '100%' : '100%',
                    display:'flex',
                    flexDirection:'row',
                    alignItems:'center',
                    justifyContent:'space-between',
                }}
            >
                <Typography
                >
                    {t("websiteTitle")}
                </Typography>
                <Typography>
                    {"www.rayanwebhub.ir"}
                </Typography>
            </Box>
        </Box>
    </Box>
  );
}
