import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Children } from "react";

import { useTranslation } from "react-i18next";


const Theme = ({children}) => {
    const {i18n} = useTranslation();
    const currentLanguage = i18n.language;

    const fonts = {
        'en': 'Roboto, Arial, sans-serif',
        'fa': 'Vazirmatn,Arial, sans-serif'
    }

    const theme = createTheme({
        typography: {
            fontFamily: fonts[currentLanguage] || 'Roboto, Arial, sans-serif'
        }
    })
    return (
        <ThemeProvider
            theme = {theme}
        >
            {children}
        </ThemeProvider>
    )
}


export default Theme;