import axios from "axios";


async function postData1(url = "", data = {}) {
    const response = await fetch(url, {
        method : "POST",
        mode: 'cors',
        cache:'no-cache',
        credentials:'same-origin',
        headers: {
            "Content-Type":"application/json"
        },
        body: JSON.stringify(data)
    })
    // if (!response.ok) {
    //     throw new Error('Network response was not ok');
    // }
    return response.json();
}

function postData(url = "", data = {}) {
    axios.post(url,data,{'Content-Type': 'application/json'})
         .then((response) =>  {
            return response.data
        })
         .catch((e) => {
            console.error(`error: ${e}`);
            throw e;
        })
}


async function getData(url = "", data = {}) {
    const response = await fetch(url, {
        method : "GET",
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            "Content-Type": "application/json"
        },

    })
    return response.json();
}

export {postData,getData}