import { useState, useEffect, Suspense } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import SamplesPage from './pages/SamplesPage';
import OrderPage from './pages/OrderPage';
import WeblogPage from './pages/WeblogPage';
import ContactPage from './pages/ContactPage';
import ProductPage from './pages/ProductPage';
import Footer from './components/Footer';
import Banner from "./components/Banner";
import heroImage from './images/hero.jpg';
import './App.css';
import NavBar from './components/NavBar';
import BackToTop from './components/BackToTop';
import BlogPostPage from './pages/BlogPostPage';
import ScrollTop from './ScrollTop';


function App() {
  const {t,i18n} = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm','md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [mediaType,setMediaType] = useState(null);

  
  useEffect(() => {
    if (isMobile) {
      setMediaType('isMobile');
    } else if (isTablet) {
      setMediaType('isTablet');
    } else if (isDesktop) {
      setMediaType('isDesktop');
    }
  },[isMobile,isTablet,isDesktop])



  const loadingPageStyle = {
    width:'100vw',
    height:'100vh',
    backgroundColor:'#0000aa',    
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
  }
  const loadingTextStyle = {
    color:"#fff",
    fontSize:'30px',
    textAlign:'center',
    fontWeight:'bold'
  }

  return (
    <div 
      className="App"
    >
      <BrowserRouter>
        <ScrollTop />
        <Banner 
          mediaType={mediaType}
          imgUrl={heroImage} 
          title = {{txt: 'title', pos:{x:'30%',y:'40%'}}} 
          subtitle={{txt: 'subTitle',pos:{x:'30%',y:'60%'}}}
          imgLoc={'bottom'}
          imgSize={'cover'}
          url = {'/home'}
        />
        <NavBar mediaType={mediaType} forFooter={false}/>
        <BackToTop mediaType={mediaType}/>
        <Suspense 
          fallback={
                    <div style = {loadingPageStyle}>
                      <h1 style = {loadingTextStyle}>{t('loading')}</h1>
                    </div>
                   }
        >
          <Routes>
            <Route exact path="/" element={<HomePage mediaType={mediaType}/>}/>
            <Route exact path="/home" element={<HomePage mediaType = {mediaType}/>}/>
            <Route path="/about" element = {<AboutPage mediaType={mediaType}/>} />
            <Route path="/samples" element = {<SamplesPage mediaType={mediaType}/>} />
            <Route path="/order" element = {<OrderPage mediaType={mediaType}/>} />
            <Route path="/blog" element = {<WeblogPage mediaType={mediaType}/>} />
            <Route path="/contact" element = {<ContactPage mediaType={mediaType}/>} />
            <Route path="/blog/blog-post1" element = {<BlogPostPage mediaType={mediaType}/>}/>
            <Route path="/product-company" element = {<ProductPage mediaType={mediaType}/>}/>
          </Routes>
        </Suspense>
        <Footer mediaType={mediaType} />

      </BrowserRouter>



    </div>
  );
}

export default App;
