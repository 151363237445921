import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

export default function TextImage({mediaType,imgUrl,title,context,imgLoc}) {
    const {t,i18n} = useTranslation(['products'])
    let itemsPosRTL;
    let itemsPosLTR;
    if (imgLoc) {
      itemsPosRTL = (imgLoc === 1) ? [1,2] : [2,1]   //[imgPos,TextPos]
      itemsPosLTR = (imgLoc === 1) ? [2,1] : [1,2]   //[imgPos,TextPos]
    } else {
      itemsPosRTL = [1,2] //[imgPos,TextPos]
      itemsPosLTR = [2,1] //[imgPos,TextPos]
    }


  return (
    <Card sx={{ 
                display:'flex',
                flexDirection: (mediaType === 'isMobile') ? 'column' : 'row',
                alignItems:'center',
                justifyContent:'center',
                gap: (mediaType === 'isDesktop') ? '60px' : '30px'
              }}>
      <CardMedia
        component="img"
        image={imgUrl}
        alt="Company Website"
        sx = {{
                width:(mediaType === 'isDesktop') ? '50%' : '100%',
                order: (mediaType === 'isDesktop') ? 
                  (i18n.dir() === 'rtl') ? itemsPosRTL[0] : itemsPosLTR[0] : 1 
              }}
      />
      <CardContent 
          sx = {{
                  width:(mediaType === 'isDesktop') ? '50%' : '100%',
                  display:'flex',
                  flexDirection:'column',
                  gap:'30px',
                  order: (mediaType === 'isDesktop') ?
                    (i18n.dir() === 'rtl') ? itemsPosRTL[1] : itemsPosLTR[1] : 1
              }}>
        <Typography
          variant = "h6"
          dir = {i18n.dir()}
        >
            {title}
            {/* {t(title)} */}
        </Typography>
        <Typography 
            variant="body2" 
            color="text.secondary"
            align = "justify"
            dir = {i18n.dir()}
        >
          {context}
          {/* {t(context)} */}
        </Typography>
      </CardContent>
    </Card>
  );
}