import { useState, useEffect,useRef } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { useSpring,animated } from '@react-spring/web';


const Search = styled('div')(({theme}) => ({
  display:'flex',
  flexDirection:'row',
  alignItems:'center',
  borderRadius: theme.shape.borderRadius,
  width:'10vw',
  padding:'0.5vw',  
}))

const SearchIconWrapper = styled('div')(({theme}) => ({

  // border:'1px solid red'
}))
export default function SearchAppBar() {
  const [active,setActive] = useState(false);
  const ref = useRef(null);
  const springs = useSpring({
    width: active ? '300px' : '200px',
  })

  useEffect(() => {
    window.addEventListener('mousedown',handleOutSideClick);
    return () => {
      window.addEventListener('mousedown',handleOutSideClick);
    }
  },[ref])

  const handleClick = () => {
    setActive(true);
  }
  const handleOutSideClick = (event) =>  {
    if (!ref.current?.contains(event.target)) {
      setActive(false);
    }
  }
  return (
    <animated.div
      onClick={() => {handleClick()}}
      style = {{
        width: springs.width
      }}
      ref = {ref}
    >
      <Search
        id = 'text-field-container'
        sx = {{        
          width:'100%'
        }}
      >
        <SearchIconWrapper>
          <SearchIcon 
            color = 'disabled'
            fontSize='large'
          />        
        </SearchIconWrapper>      
        <TextField 
          variant='standard'
          fullWidth
        /> 
      </Search>
    </animated.div>
  )
}