import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../axiosConfig';
import { useForm, Controller } from 'react-hook-form';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { postData } from '../connect';
import { reFormatDate,reFormatTime } from '../utlility';

export default function FeedBackForm({mediaType}) {
    const [successMsg,setSuccessMsg] = useState('');
    const {t,i18n} = useTranslation(['comment']);

    const {
      register,
      formState: {errors},
      clearErrors,
      control,
      reset,
      handleSubmit
    } = useForm();

    const onSubmit = (data) => {
      const data1 = {
        fullname: `'${data.fullname.toString()}'`,
        msg: `'${data.comment.toString()}'`,
        dateofregistration: reFormatDate(new Date()),
        timeofregistration: reFormatTime(new Date()),
        notes: `' '`,
        status: `'${'just registered'}'`
      }
      axiosInstance.post('feedback.php',data1, {headers: {
        'Content-Type': 'Application/json',
      }})
      .then((response) => {
        reset();
        setSuccessMsg('success');
      })
      .catch((e) => {
        setSuccessMsg('failed');
        console.error(e);
      })
      // postData('http://127.0.0.1:4000/api/feedback',data1)      
      //   .then((result) => {
      //     reset();
      //     setSuccessMsg('success');
      //   })
      //   .catch((e) => {
      //     setSuccessMsg('failed');
      //     console.error(e);
      //   })
    }

    const clearMsg = () => {
      clearErrors();
      setSuccessMsg('');
    }
    
  return (
    <Box
      sx = {{
        width:'100%',
      }}
    >
      <Typography
        variant='h5'
        sx = {{
          my: 5
        }}
      >
        {t('feedBackTitle')}
      </Typography>
      <Box
        component ="form"
        onSubmit = {handleSubmit(onSubmit)}
        sx = {{
          width:'80%',
          mx:'auto',
          display:'flex',
          flexDirection:'column',
          alignItems:'start',
          gap:'30px',
      }}
      >
          <TextField
            //   error
            sx = {{
              width:'100%',
              '& .MuiInputLabel-root': {
                transformOrigin: i18n.dir() === 'rtl' ? 'top right' : 'top left',
                right:'31px',
              },
            }}
            id="fullname"
            {...register('fullname',{required:true, minLength: 5, pattern: /\b[A-Za-z][\w]*/i})}
            label={t('fullname')}
            placeholder={t('fullname')}
            onChange={() => {clearMsg()}}
          />
        
        {(errors.fullname?.type !== undefined) && (
          <p style = {{color:'red'}}>{t('fullNameRequired',{ns:'common'})}</p>
        )}
          <TextField
            //   error
            sx = {{
              width:'100%',
              '& .MuiInputLabel-root': {
                transformOrigin:i18n.dir() === 'rtl' ? 'top right' : 'top left',
                right:'31px',
              },
            }}
            id="comment"
            {...register('comment',{required:true})}
            label={t('comment')}
            placeholder={t('comment')}
            multiline
            rows = {5}
            onChange={() => {clearMsg()}}
          />
          {(errors.comment?.type !== undefined) && (
            <p style = {{color:'red'}}>{t('messageRequired',{ns:'common'})}</p>
          )}

          <Typography style = {{color: successMsg === 'success' ? "green": 'red'}}>{t(`${successMsg}`,{ns:'comment'})}</Typography>        

          <Box
            sx = {{display:'flex',flexDirection:'row',alignSelf:'end',gap:'30px'}}
          >
            <Button 
              variant="contained"
              color="primary"
              type = "submit"
              sx = {{

                alignSelf:'end'
              }}
            >
                {t("send")}
            </Button>
            <Button 
              variant="contained"
              color="primary"
              type = "reset"
              sx = {{

                alignSelf:'end'
              }}
            >
                {t("clear")}
            </Button>

          </Box>
      </Box>

    </Box>
  );
}
