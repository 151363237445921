import Container from '@mui/material/Container';
import Box  from '@mui/material/Box';

import ProductsGallery from '../components/ProductsGallery';
import Banner from '../components/Banner';
import SamplesGallery from '../components/SamplesGallery';
import OrdersSection from '../components/OrdersSection'
import BlogPostsGallery from '../components/BlogPostsGallery';
import ContactSection from '../components/ContactSection';
import banner1 from './../images/banner1.jpg';
import banner2 from './../images/banner2.jpg';
import banner3 from './../images/banner3.jpg';
import banner4 from './../images/banner4.jpg';


export default function HomePage({mediaType}) {
    return (
        <Container sx = {{display:'flex',flexDirection:'column',alignItems:'center',gap:'100px',marginTop:'100px'}}>
            <ProductsGallery mediaType={mediaType} />
            <Box
                sx = {{
                    width:'100vw',
                }}
            >
                <Banner mediaType = {mediaType}                         
                        imgUrl = {banner1} 
                        title = {{txt: '', pos:{x:'30%',y:'40%'}}} 
                        subtitle={{txt: '',pos:{x:'30%',y:'60%'}}}
                        imgLoc={(mediaType === 'isDesktop') ? 'top' : 'right'}
                        imgSize = {(mediaType === 'isDesktop') ? 'cover' : 'contain'}
                />
            </Box>
            <SamplesGallery mediaType = {mediaType} />
            <Box
                sx = {{
                    width:'100vw',
                }}
            >
                <Banner mediaType = {mediaType}                         
                        imgUrl = {banner2} 
                        title = {{txt: '', pos:{x:'30%',y:'40%'}}} 
                        subtitle={{txt: '',pos:{x:'30%',y:'60%'}}}
                        imgLoc={(mediaType === 'isDesktop') ? 'left' : 'right'}
                        imgSize = {(mediaType === 'isDesktop') ? 'cover' : 'cover'}
                />
            </Box>
            <OrdersSection mediaType={mediaType} />
            <Box
                sx = {{
                    width:'100vw',
                }}
            >
                <Banner mediaType = {mediaType}                         
                        imgUrl = {banner3} 
                        title = {{txt: '', pos:{x:'30%',y:'40%'}}} 
                        subtitle={{txt: '',pos:{x:'30%',y:'60%'}}}
                        imgLoc={(mediaType === 'isDesktop') ? 'left' : 'right'}
                        imgSize = {(mediaType === 'isDesktop') ? 'cover' : 'cover'}
                />
            </Box>
            <BlogPostsGallery mediaType={mediaType}/>
            <Box
                sx = {{
                    width:'100vw',
                }}
            >
                <Banner mediaType = {mediaType}                         
                        imgUrl = {banner4} 
                        title = {{txt: '', pos:{x:'30%',y:'40%'}}} 
                        subtitle={{txt: '',pos:{x:'30%',y:'60%'}}}
                        imgLoc={(mediaType === 'isDesktop') ? 'left' : 'center'}
                        imgSize = {(mediaType === 'isDesktop') ? 'cover' : 'contain'}
                />
            </Box>
            <ContactSection mediaType={mediaType} />
            <div ><br/><br/><br/><br/></div>
        </Container>

    )
}
