import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Box  from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Banner from '../components/Banner';
import ContactSection from '../components/ContactSection';
import BlogPostCategories from '../components/BlogPostCategories';
import SocialMediaIconSet from '../components/SocialMediaIconSet';
import Comments from '../components/Comments';
import FeedBackForm from './../components/FeedBackForm';
import heroImageUrl from './../images/post1.jpg';
import banner4 from './../images/banner4.jpg';

export default function BlogPostPage({mediaType}) {
    const {t,i18n} = useTranslation(['blogGeneral'])
    return (
        <Container sx = {{display:'flex',flexDirection:'column',alignItems:'center',gap:'100px',marginTop:'100px'}}>
            <Typography 
                variant="h5"
                sx = {{
                    alignSelf:'start'
                }}
            >
                {t('BlogPageTitle')}
            </Typography>
            <Typography 
                variant="h6"
                sx = {{
                    fontSize: (mediaType === 'isMobile') ? '20px' : '30px',
                    textAlign:'center'

                }}
            >
                {t('post1.0.title')}
            </Typography>
            <Box
                sx = {{
                    width:'100vw',
                }}
            >
                <Banner mediaType = {mediaType}                         
                        imgUrl = {heroImageUrl} 
                        title = {{txt: '', pos:{x:'30%',y:'40%'}}} 
                        subtitle={{txt: '',pos:{x:'30%',y:'60%'}}}
                        imgLoc={(mediaType === 'isDesktop') ? 'top' : 'top'}
                        imgSize = {(mediaType === 'isDesktop') ? 'contain' : 'contain'}
                />
            </Box>
            <Typography 
                variant="body1"
                sx = {{
                    textAlign:'justify',
                    fontSize: (mediaType === 'isDesltop') ? '20px' : 
                              (mediaType === 'isTablet') ? '24px' : '16px',
                    lineHeight: (mediaType === 'isDesltop') ? '60px' : 
                                (mediaType === 'isTablet') ? '60px' : '40px',
                    px:3
                }}
            >
                {t('post1.2.context')}
            </Typography>
            <div ><br/><br/><br/><br/></div>
            <Box
                sx = {{
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'start',
                    gap:'30px',
                }}
            >
                <Typography 
                    variant="h6"
                    sx = {{
                        alignSelf:'start',
                        // fontSize: (mediaType === 'isDesktop') ? '16px' :
                        //           (mediaType === 'isTablet') ? '20px' :
                        //           (mediaType === 'isMobile') ? '16px' : '16px',
                    }}
                >
                    {t('aboutWriter')}
                </Typography>
                <Typography 
                    variant="body1"
                    sx = {{
                        textAlign:'justify',
                        fontSize: (mediaType === 'isDesktop') ? '18px' :
                                  (mediaType === 'isTablet') ? '16px' :
                                  (mediaType === 'isMobile') ? '14px' : '14px',
                        lineHeight: (mediaType === 'isDesktop') ? '60px' :
                                  (mediaType === 'isTablet') ? '50px' :
                                  (mediaType === 'isMobile') ? '50px' : '16px',
                        
                    }}
                >
                    {t('post1.3.aboutWriter')}
                </Typography>
                <Box 
                    sx = {{
                        alignSelf:'end',
                        width: (mediaType === 'isDesktop') ? '20%' :
                                  (mediaType === 'isTablet') ? '30%' :
                                  (mediaType === 'isMobile') ? '50%' : '50%',
                    }}
                >
                    <SocialMediaIconSet />
                </Box>
            </Box>
            <Comments mediaType={mediaType}/>
            <FeedBackForm />

            <BlogPostCategories mediaType={mediaType} />

            <Box
                sx = {{
                    width:'100vw',
                }}
            >
                <Banner mediaType = {mediaType}                         
                        imgUrl = {banner4} 
                        title = {{txt: '', pos:{x:'30%',y:'40%'}}} 
                        subtitle={{txt: '',pos:{x:'30%',y:'60%'}}}
                        imgLoc={(mediaType === 'isDesktop') ? 'left' : 'center'}
                        imgSize = {(mediaType === 'isDesktop') ? 'cover' : 'contain'}
                />
            </Box>
            <ContactSection mediaType={mediaType} />
            <div ><br/><br/><br/><br/></div>
        </Container>

    )
}
