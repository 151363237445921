import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box'
import ContactForm from "./ContactForm";

export default function ContactSection({mediaType}) {
    const {t,i18n} = useTranslation(['contact'])
    return (
        <Box
            sx = {{
                width:'100%',
                display:'flex',
                flexDirection:'column',
                gap:'30px',
            }}
        >
            <Typography
                variant="h5"
            >
                {t("title")}
            </Typography>

            <ContactForm mediaType={mediaType} />
        </Box>
    )
}