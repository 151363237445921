import { useState,useEffect } from "react"

import Fab from '@mui/material/Fab';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export default function BackToTop({mediaType}) {
    const [isScrolling,setIsScrolling] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll',handleScrolling);
        return () => {
            window.removeEventListener('scroll',handleScrolling);
        }
    })
    const handleScrolling = () => {
        if (window.scrollY >= window.innerHeight / 2) {
            setIsScrolling(true);
        } else {
            setIsScrolling(false);
        }
    }
    const scrollTop = () => {
        window.scrollTo({
            top:0,
            behavior:'smooth'
        })
    }

    return (
        <>
            {
                isScrolling &&
                    <Fab
                        sx = {{
                            position:'fixed',
                            right:(mediaType === 'isDesktop') ? '30px' :
                                   (mediaType === 'isTablet') ? '50%':
                                   (mediaType === 'isMobile') ? '50%': '50%',
                            transform:(mediaType !== 'isDesktop') ? 'translateX(50%)' : 'none',
                            bottom:'30px',
                        }} 
                        onClick={() => {scrollTop()}}  
                    >
                        <ExpandLessIcon />
                    </Fab>
            }
        </>        
    )
}